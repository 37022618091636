import { getUnixTime } from 'date-fns';
import {
  InformationsAbonnementForProcessDto,
  InformationsClientForProcessAbonnementDto,
  InformationsPaiementDto,
  InformationsProprietaireDto,
  InformationsSegmentationDto
} from 'src/app/api/models/interface/InformationsForProcessDto';
import {
  InformationsAbonnementForProcessMDto,
  InformationsClientForProcessAbonnementMDto,
  InformationsFactureEntrantForProcessAbonnementMDto,
  InformationsPaiementMDto,
  InformationsProprietaireMDto,
  InformationsSegmentationMDto
} from 'src/app/api/models/interface/MutationProcess';
import { Wizard } from 'src/app/core/models/wizard.model';
import { ApiRequestBodyUtils } from './apiRequestBodyUtils';

export function getAbonnementDto(
  wizard: Wizard,
  numAbonnement: string = ''
): InformationsAbonnementForProcessMDto {
  const civiliteBody = ApiRequestBodyUtils.GenerateCiviliteDtoRequestBody(
    getCivilite(wizard.client.codeCivilite)
  );

  let numeroRue = 0;
  if (wizard.client?.numeroRue != null) {
    if (!Number.isNaN(wizard.client?.numeroRue)) numeroRue = Number(wizard.client.numeroRue);
  }

  let numeroDansLaRue = 0;
  if (wizard.client?.numeroDansLaRue != null) {
    if (!Number.isNaN(wizard.client?.numeroDansLaRue))
      numeroDansLaRue = Number(wizard.client.numeroDansLaRue);
  }

  const rueBody = ApiRequestBodyUtils.GenerateRueDtoRequestBody(numeroRue, undefined, '', '');

  const adresseBody = ApiRequestBodyUtils.GenerateAdresseMDtoRequestBody(
    numeroDansLaRue,
    '',
    '',
    wizard.client.etage ?? '',
    rueBody
  );

  return ApiRequestBodyUtils.GenerateInformationsAbonnementForProcessMDtoRequestBody(
    numAbonnement,
    wizard.infoSegmentation.activite ?? '',
    civiliteBody,
    wizard.client.nom ?? '',
    adresseBody,
    wizard.client.dateNaissance
      ? '/Date(' +
          getUnixTime(
            new Date(
              Number(wizard.client.dateNaissance?.split('/')[2]),
              Number(wizard.client.dateNaissance?.split('/')[1]),
              Number(wizard.client.dateNaissance?.split('/')[0])
            )
          ) +
          '000+0200)/'
      : null,
    wizard.client.numeroSIRET ?? '',
    wizard.client.codeAPE ?? '',
    wizard.infoSegmentation.observation ?? '',
    wizard.infoSegmentation.commentaire ?? '',
    wizard.infoSegmentation.nombreOccupant,
    wizard.client.identifiantTiersAbo ?? '',
    ''
  );
}

export function getClientDto(wizard: Wizard): InformationsClientForProcessAbonnementMDto {
  return ApiRequestBodyUtils.GenerateInformationsClientForProcessAbonnementMDtoRequestBody(
    wizard.client.civiliteClient ? getCivilite(wizard.client.civiliteClient) : '',
    wizard.client.raisonSociale ?? '',
    wizard.client.nom ?? '',
    wizard.client.prenomClient ?? '',
    wizard.client.telephone1 != '' ? wizard.client.telephone1 : null,
    wizard.client.telephone2 != '' ? wizard.client.telephone2 : null,
    '',
    wizard.client.email ?? '',
    wizard.client.dateNaissance
      ? '/Date(' +
          getUnixTime(
            new Date(
              Number(wizard.client.dateNaissance?.split('/')[2]),
              Number(wizard.client.dateNaissance?.split('/')[1]),
              Number(wizard.client.dateNaissance?.split('/')[0])
            )
          ) +
          '000+0200)/'
      : null,
    wizard.client.numeroSIRET ?? '',
    wizard.client.codeAPE ?? '',
    '',
    wizard.client.identifiantTiersDest ?? '',
    '',
    false,
    wizard.client.cpl ?? '',
    '',
    wizard.client.adresse ?? '',
    wizard.client.ville ?? '',
    wizard.client.codePostal ?? '',
    wizard.client.bureauDistributeur ?? '',
    '',
    wizard.client.etatVerificationAdresse ?? ''
  );
}

export function getPaiementDto(wizard: Wizard): InformationsPaiementMDto {
  const CanalAcquisitionSepaDto = ApiRequestBodyUtils.GenerateCanalAcquisitionSepaDtoRequestBody(
    wizard.referenceBancaire.acquisitionConsentement
  );

  const ReferenceBancaireDbDto = ApiRequestBodyUtils.GenerateReferenceBancaireMDtoRequestBody(
    '',
    wizard.referenceBancaire.titulaire ?? '',
    wizard.referenceBancaire.domiciliation ?? '',
    wizard.referenceBancaire.iban ?? '',
    wizard.referenceBancaire.bic ?? '',
    wizard.referenceBancaire.numeroRUM ?? '',
    wizard.referenceBancaire.dateRUM
      ? '/Date(' +
          getUnixTime(
            new Date(
              Number(wizard.referenceBancaire.dateRUM?.split('/')[2]),
              Number(wizard.referenceBancaire.dateRUM?.split('/')[1]),
              Number(wizard.referenceBancaire.dateRUM?.split('/')[0])
            )
          ) +
          '000+0200)/'
      : null,
    wizard.referenceBancaire.acquisitionConsentement ? CanalAcquisitionSepaDto : null
  );

  return ApiRequestBodyUtils.GenerateInformationsPaiementMDtoRequestBody(
    '',
    false,
    wizard.referenceBancaire.modePaiement ?? '',
    wizard.referenceBancaire.dateValiditeCB
      ? '/Date(' +
          getUnixTime(
            new Date(
              Number(wizard.referenceBancaire.dateValiditeCB?.split('/')[2]),
              Number(wizard.referenceBancaire.dateValiditeCB?.split('/')[1]),
              Number(wizard.referenceBancaire.dateValiditeCB?.split('/')[0])
            )
          ) +
          '000+0200)/'
      : null,
    wizard.referenceBancaire.jourPrelevement,
    ReferenceBancaireDbDto,
    false,
    '',
    ''
  );
}

export function getProprietaireDto(wizard: Wizard): InformationsProprietaireMDto {
  return ApiRequestBodyUtils.GenerateInformationsProprietaireMDtoRequestBody(
    '',
    wizard.proprietaire.civilite ?? '',
    wizard.proprietaire.proprietaireGerant ?? '',
    wizard.proprietaire.numeroTelephone ?? '',
    wizard.proprietaire.adressePostale ?? '',
    wizard.proprietaire.ville ?? '',
    wizard.proprietaire.codePostal ?? '',
    wizard.proprietaire.email ?? ''
  );
}

export function getSegmentationDto(
  wizard: Wizard,
  numAbonnement: string = ''
): InformationsSegmentationMDto {
  return ApiRequestBodyUtils.GenerateInformationsSegmentationMDtoRequestBody(
    numAbonnement,
    wizard.infoSegmentation.activite ?? '',
    wizard.infoSegmentation.typeAbonne ?? '',
    wizard.infoSegmentation.typeUtilisateur ?? '',
    wizard.infoSegmentation.particulariteContrat ?? '',
    wizard.infoSegmentation.sensibilite ?? '',
    wizard.infoSegmentation.typeHabitation ? Number(wizard.infoSegmentation.typeHabitation) : null,
    wizard.infoSegmentation.typeClientRisque ?? '',
    '',
    ''
  );
}

export function getFactureEntrantDto(
  wizard: Wizard
): InformationsFactureEntrantForProcessAbonnementMDto {
  return ApiRequestBodyUtils.GenerateInformationsFactureEntrantForProcessAbonnementMDtoRequestBody(
    wizard.factureEntrant.fraisAcces,
    wizard.factureEntrant.nbMoisFacture,
    wizard.factureEntrant.nbJour,
    wizard.factureEntrant.nbPrimeFixe,
    wizard.factureEntrant.dateEffet
      ? '/Date(' + getUnixTime(new Date(wizard.factureEntrant.dateEffet)) + '000+0200)/'
      : null,
    wizard.factureEntrant.diametreFacturation
      ? parseInt(wizard.factureEntrant.diametreFacturation)
      : null
  );
}

export function getAbonnementDto_DB(wizard: Wizard): InformationsAbonnementForProcessDto {
  const civiliteBody = ApiRequestBodyUtils.GenerateCiviliteDtoRequestBody(
    getCivilite(wizard.client.codeCivilite)
  );
  const rueBody = ApiRequestBodyUtils.GenerateRueDbDtoRequestBody(
    Number(wizard.localisation.commune),
    Number(wizard.localisation.rue),
    '',
    ''
  );

  const adresseBody = ApiRequestBodyUtils.GenerateAdresseDtoRequestBody(
    Number(wizard.localisation.numeroRue),
    wizard.localisation.cpl ?? '',
    wizard.localisation.etage ?? '',
    wizard.localisation.complementAdresse ?? '',
    rueBody
  );

  return ApiRequestBodyUtils.GenerateInformationsAbonnementForProcessDtoRequestBody(
    '',
    wizard.infoSegmentation.activite ?? '',
    civiliteBody,
    wizard.client.nom ?? '',
    adresseBody,
    wizard.client.dateNaissance
      ? '/Date(' +
          getUnixTime(
            new Date(
              Number(wizard.client.dateNaissance?.split('/')[2]),
              Number(wizard.client.dateNaissance?.split('/')[1]),
              Number(wizard.client.dateNaissance?.split('/')[0])
            )
          ) +
          '000+0200)/'
      : null,
    wizard.client.numeroSIRET ?? '',
    wizard.client.codeAPE ?? '',
    wizard.infoSegmentation.observation ?? '',
    wizard.infoSegmentation.commentaire ?? '',
    wizard.infoSegmentation.nombreOccupant,
    wizard.client.identifiantTiersAbo ?? '',
    ''
  );
}

export function getClientDto_DB(wizard: Wizard): InformationsClientForProcessAbonnementDto {
  return ApiRequestBodyUtils.GenerateClientDbRequestBody(
    getCivilite(wizard.client.civiliteClient),
    wizard.client.raisonSociale,
    wizard.client.nom,
    wizard.client.telephone1 ? Number(wizard.client.telephone1) : null,
    wizard.client.email,
    wizard.client.identifiantTiersDest,
    '0',
    wizard.localisation.rue ?? '',
    wizard.localisation.codePostal ?? '',
    wizard.localisation.ville ?? '',
    '1'
  );
}

export function getPaiementDto_DB(wizard: Wizard): InformationsPaiementDto {
  const CanalAcquisitionSepaDto = ApiRequestBodyUtils.GenerateCanalAcquisitionSepaDtoRequestBody(
    wizard.referenceBancaire.acquisitionConsentement
  );

  const ReferenceBancaireDbDto = ApiRequestBodyUtils.GenerateReferenceBancaireDbDtoRequestBody(
    wizard.referenceBancaire.acquisitionConsentement ? CanalAcquisitionSepaDto : null,
    wizard.referenceBancaire.bic,
    wizard.referenceBancaire.iban,
    wizard.referenceBancaire.dateRUM
      ? '/Date(' +
          getUnixTime(
            new Date(
              Number(wizard.referenceBancaire.dateRUM?.split('/')[2]),
              Number(wizard.referenceBancaire.dateRUM?.split('/')[1]),
              Number(wizard.referenceBancaire.dateRUM?.split('/')[0])
            )
          ) +
          '000+0200)/'
      : null,
    wizard.referenceBancaire.domiciliation,
    '',
    wizard.referenceBancaire.numeroRUM,
    wizard.referenceBancaire.titulaire
  );

  return ApiRequestBodyUtils.GeneratePaiementDbRequestBody(
    false,
    wizard.referenceBancaire.modePaiement,
    undefined,
    wizard.referenceBancaire.jourPrelevement,
    ReferenceBancaireDbDto,
    false,
    '',
    ''
  );
}

export function getProprietaireDto_DB(wizard: Wizard): InformationsProprietaireDto {
  return ApiRequestBodyUtils.GenerateProprietaireDbRequestBody(
    wizard.proprietaire.civilite,
    wizard.proprietaire.proprietaireGerant,
    wizard.proprietaire.numeroTelephone,
    wizard.proprietaire.adressePostale,
    wizard.proprietaire.ville,
    wizard.proprietaire.codePostal,
    wizard.proprietaire.email
  );
}

export function getSegmentationDto_DB(wizard: Wizard): InformationsSegmentationDto {
  return ApiRequestBodyUtils.GenerateSegmentationDbRequestBody(
    wizard.infoSegmentation.activite,
    wizard.infoSegmentation.typeAbonne,
    wizard.infoSegmentation.typeUtilisateur,
    wizard.infoSegmentation.particulariteContrat,
    wizard.infoSegmentation.sensibilite,
    wizard.infoSegmentation.typeHabitation ? Number(wizard.infoSegmentation.typeHabitation) : null,
    wizard.infoSegmentation.typeClientRisque
  );
}

function getCivilite(civilite: string | null): string {
  switch (civilite) {
    case 'MONSIEUR':
      return 'M';
    case 'MADAME':
      return 'MME';
    case 'SOCIETE':
      return 'SCI';
    default:
      return '';
  }
}
