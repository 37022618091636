import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import {
  AuthentificationGoogleRequest,
  AuthentificationRequest
} from 'src/app/api/models/request/authentification/authentificationRequest';
import { FonctionWebRequest } from 'src/app/api/models/request/autorisation-ressource/FonctionWebRequest';
import { RessourceInterventionRequest } from 'src/app/api/models/request/interventions/ressourceInterventionRequest';
import { GeneriqueResponse } from 'src/app/api/models/shared/generiqueResponse';
import { ParametersRequest } from 'src/app/api/models/shared/parametersRequest';
import { AppConfig } from 'src/app/app.config';
import { WaterpHttpService } from 'src/app/shared/services/waterp-http.service';
import { Ressource } from '../models/class/Ressource';
import { Authorisations } from '../models/class/authorisation/authorisations';
import { AbstractService } from './abstract.service';
import { RessourceLightDto } from '../models/request/ressource/ressource-light.dto';
import { RessourceFormDto } from '../models/request/ressource/ressource-form.dto';
import { ChangerMotDePasseRequest } from '../models/request/authentification/changerMotDePasseRequest';
import { EnvoyerEmailReinitialiserMotDePasseRequest } from '../models/request/authentification/envoyerEmailReinitialiserMotDePasseRequest';
import { ReinitialiserMotDePasseAvecTokenRequest } from '../models/request/authentification/reinitialiserMotDePasseAvecTokenRequest';

@Injectable({
  providedIn: 'root'
})
export class RessourceService extends AbstractService {
  parametersRequest!: ParametersRequest;
  constructor(
    config: AppConfig,
    private router: Router,
    private waterpHttpService: WaterpHttpService
  ) {
    super(config);
  }

  //#region /Ressource/AuthentifierRessource: Cette méthode permet d'authentifier une ressource et de récupérer ses informations
  /**
   * @param body
   */
  public authentifierRessource(body?: AuthentificationRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Ressource/AuthentifierRessource`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Ressource/AuthentifierRessourceFromGoogle: Recupérer la ressource en fonction du profil Google
  /**
   * @param body
   */
  public authentifierRessourceFromGoogle(
    body?: AuthentificationGoogleRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Ressource/AuthentifierRessourceFromGoogle`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region /Ressource/GetRessourcePrevuePourIntervention
  /**
   * @description Récupérer la ressource prévue pour une intervention
   * @param body
   */
  public getRessourcePrevuePourIntervention(
    body: RessourceInterventionRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Ressource/GetRessourcePrevuePourIntervention`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
  //#region /Ressource/RechercherAgentMultipleFields
  /**
   * @param text
   */
  public rechercherAgentMultipleFields(text: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Ressource/RechercherAgentMultipleFields',
      parameters: [{ name: 'value', value: text }]
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Ressource/SetFonctionsWeb
  /**
   * @param body
   */
  public setFonctionsWeb(body: FonctionWebRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Ressource/SetFonctionsWeb`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion
  //#region /Ressource/GetListRessourcePrevuePourInterventionByUnite
  /**
   * @param body
   */
  public getListRessourcePrevuePourInterventionByUnite(body: any): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Ressource/GetListRessourcePrevuePourInterventionByUnite`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region Ressource/VerifierExpirationToken
  /**
   * @description
   * @param body
   */
  public verifierExpirationTokenNouveauMotDePasse(
    tokenReinitialisationMdp: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Ressource/VerifierExpirationTokenNouveauMotDePasse',
      parameters: [{ name: 'token', value: tokenReinitialisationMdp }]
    };

    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion
  //#region /Ressource/GetRessources
  public getRessources(body: RessourceLightDto): Observable<Ressource[]> {
    this.parametersRequest = {
      url: '/Ressource/GetRessources',
      parameters: []
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.post(path, body).pipe(
      tap((response: GeneriqueResponse) =>
        response.Code === 112 ? this.router.navigateByUrl('/authentification') : null
      ),
      map(
        (response: GeneriqueResponse) =>
          response.Result?.map((rsc: Ressource) => new Ressource(rsc)) ?? new Array<Ressource>()
      )
    );
  }
  //#endregion

  //#region /Ressource/GetProcesses
  public getProcesses(): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Ressource/GetProcedures',
      parameters: []
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.post(path, null);
  }
  //#endregion

  //#region /Ressource/GetWebFunctions
  public getWebFunctions(): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Ressource/GetFonctionWebs',
      parameters: []
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.post(path, null);
  }
  //#endregion

  //#region /Ressource/GetUnities
  public getUnities(notLimited: boolean = false): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Ressource/GetUnites',
      parameters: [{ name: 'notLimited', value: notLimited }]
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Ressource/GetNiveauxHierarchiqueByUnite
  public GetNiveauxHierarchiqueByUnite(codeUnite: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Ressource/GetNiveauxHierarchiqueByUnite',
      parameters: [{ name: 'codeUnite', value: codeUnite }]
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //GetNomHierarchieParNiveau
  //#region /Ressource/GetNomHierarchieParNiveau
  public GetNomHierarchieParNiveau(
    codeNiveauHierarchie: string,
    codeUnite: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Ressource/GetNomHierarchieParNiveau',
      parameters: [
        { name: 'codeNiveauHierarchie', value: codeNiveauHierarchie },
        { name: 'codeUnite', value: codeUnite }
      ]
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //GetAutorisationsByMatriculeRessource
  //#region /Ressource/GetAutorisationsByMatriculeRessource
  public getAutorisationsByMatriculeRessource(matricule?: string): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Ressource/GetAutorisationsByMatriculeRessource',
      parameters: [{ name: 'matricule', value: matricule }]
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //#region /Ressource/GetRessourcesWithCoordonneesUrgenceByUnite
  public GetRessourcesWithCoordonneesUrgenceByUnite(
    codeUnite: string
  ): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Ressource/GetRessourcesWithCoordonneesUrgenceByUnite',
      parameters: [{ name: 'codeUnite', value: codeUnite }]
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.get(path, this.parametersRequest);
  }
  //#endregion

  //RechercherNewMatricule (renvoie matricule disponible en base)
  //#region /Ressource/RechercherNewMatricule
  public RechercherNewMatricule(): Observable<String> {
    this.parametersRequest = {
      url: '/Ressource/RechercherNewMatricule',
      parameters: []
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService
      .get(path, this.parametersRequest)
      .pipe(map((response: GeneriqueResponse) => String(response)));
  }
  //#endregion

  //#region /Ressource/CreateRessource
  // Créer ressource
  public createRessource(body: RessourceFormDto): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Ressource/CreateRessource',
      parameters: []
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.post(path, body);
  }
  //#region
  /**
   * @param body
   */
  public updateRessource(body: RessourceFormDto): Observable<GeneriqueResponse> {
    this.parametersRequest = {
      url: '/Ressource/UpdateRessource',
      parameters: []
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService.put(path, body);
  }
  //#endregion

  //#region
  /**
   * @param matricule
   */
  public GetInformationsRessourceByMatricule(matricule?: string): Observable<Ressource> {
    this.parametersRequest = {
      url: '/Ressource/GetInformationsRessourceByMatricule',
      parameters: [{ name: 'matricule', value: matricule }]
    };
    const path = this.basePath + this.parametersRequest.url;
    return this.waterpHttpService
      .get(path, this.parametersRequest)
      .pipe(map((response: GeneriqueResponse) => new Ressource(response.Result)));
  }
  //#endregion

  //#region
  /**
   * @param body
   */
  public mettreAjourMotDePasse(body?: ChangerMotDePasseRequest): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Ressource/ModifierMotDePasse`;
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region Ressource/EnvoyerEmailReinitialiserMotDePasse
  /**
   * @description
   * @param body
   */
  public envoyerEmailReinitialiserMotDePasse(
    body?: EnvoyerEmailReinitialiserMotDePasseRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Ressource/EnvoyerEmailReinitialiserMotDePasse`; // TODO vérifier l'endpoint
    return this.waterpHttpService.post(path, body);
  }
  //#endregion

  //#region Ressource/ReinitialiserMotDePasseAvecToken
  /**
   * @description
   * @param body
   */
  public reinitialiserMotDePasseAvecToken(
    body?: ReinitialiserMotDePasseAvecTokenRequest
  ): Observable<GeneriqueResponse> {
    const path = `${this.basePath}/Ressource/ReinitialiserMotDePasseAvecToken`;
    return this.waterpHttpService.put(path, body);
  }
  //#endregion
}
