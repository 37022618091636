import { Option } from 'src/app/shared/models/option';
import { Centre } from '../interface/Centre';
import { Societe } from '../interface/Societe';

export class Unite {
  private Numero: string;
  private NomAbrege: string;
  private Nom: string;
  private NumeroUniteResponsable: string;
  private IsActive: string;
  private CodeSociete: string;
  private CodeTypeUnite: string;
  private NumeroCentre: string;
  private IndicateurModeReleve: string;
  private AdressePda: string;
  private Email: string;
  private CcEmail: string;
  private NumeroTelPda: string;
  private IndicateurModeAffectationLot: string;
  private NumeroService: string;
  private IndicateurIntervention: string;
  private IndicateurReleve: string;
  private IndicateurInteventionAdministrative: string;
  private IndicateurMagasin: string;
  private IndicateurService: string;
  private IndicateurInterventionNonPlanifiee: string;
  private NombreJourAvantDeplanification: number | null;
  private NumeroUniteVeolia: string;
  private IndicateurValeurEditeur: string;
  private IndicateurBasculeMoveo: boolean | null;
  private IndicateurTravo: boolean | null;
  private Centre!: Centre;
  private Societe!: Societe;

  constructor(distantUnite?: Unite) {
    this.Numero = distantUnite?.Numero ?? '';
    this.NomAbrege = distantUnite?.NomAbrege ?? '';
    this.Nom = distantUnite?.Nom ?? '';
    this.NumeroUniteResponsable = distantUnite?.NumeroUniteResponsable ?? '';
    this.IsActive = distantUnite?.IsActive ?? '';
    this.CodeSociete = distantUnite?.CodeSociete ?? '';
    this.CodeTypeUnite = distantUnite?.CodeTypeUnite ?? '';
    this.NumeroCentre = distantUnite?.NumeroCentre ?? '';
    this.IndicateurModeReleve = distantUnite?.IndicateurModeReleve ?? '';
    this.AdressePda = distantUnite?.AdressePda ?? '';
    this.Email = distantUnite?.Email ?? '';
    this.CcEmail = distantUnite?.CcEmail ?? '';
    this.NumeroTelPda = distantUnite?.NumeroTelPda ?? '';
    this.IndicateurModeAffectationLot = distantUnite?.IndicateurModeAffectationLot ?? '';
    this.NumeroService = distantUnite?.NumeroService ?? '';
    this.IndicateurIntervention = distantUnite?.IndicateurIntervention ?? '';
    this.IndicateurReleve = distantUnite?.IndicateurReleve ?? '';
    this.IndicateurInteventionAdministrative =
      distantUnite?.IndicateurInteventionAdministrative ?? '';
    this.IndicateurMagasin = distantUnite?.IndicateurMagasin ?? '';
    this.IndicateurService = distantUnite?.IndicateurService ?? '';
    this.IndicateurInterventionNonPlanifiee =
      distantUnite?.IndicateurInterventionNonPlanifiee ?? '';
    this.NombreJourAvantDeplanification = distantUnite?.NombreJourAvantDeplanification ?? null;
    this.NumeroUniteVeolia = distantUnite?.NumeroUniteVeolia ?? '';
    this.IndicateurValeurEditeur = distantUnite?.IndicateurValeurEditeur ?? '';
    this.IndicateurBasculeMoveo = distantUnite?.IndicateurBasculeMoveo ?? false;
    this.IndicateurTravo = distantUnite?.IndicateurTravo ?? false;
    if (distantUnite) {
      this.Centre = distantUnite.Centre;
      this.Societe = distantUnite.Societe;
    }
  }

  public getNumero(): string {
    return this.Numero;
  }

  public setNumero(Numero: string): void {
    this.Numero = Numero;
  }

  public getNomAbrege(): string {
    return this.NomAbrege;
  }

  public setNomAbrege(NomAbrege: string): void {
    this.NomAbrege = NomAbrege;
  }

  public getNom(): string {
    return this.Nom;
  }

  public setNom(Nom: string): void {
    this.Nom = Nom;
  }

  public getNumeroUniteResponsable(): string {
    return this.NumeroUniteResponsable;
  }

  public setNumeroUniteResponsable(NumeroUniteResponsable: string): void {
    this.NumeroUniteResponsable = NumeroUniteResponsable;
  }

  public getIsActive(): string {
    return this.IsActive;
  }

  public setIsActive(IsActive: string): void {
    this.IsActive = IsActive;
  }

  public getCodeSociete(): string {
    return this.CodeSociete;
  }

  public setCodeSociete(CodeSociete: string): void {
    this.CodeSociete = CodeSociete;
  }

  public getCodeTypeUnite(): string {
    return this.CodeTypeUnite;
  }

  public setCodeTypeUnite(CodeTypeUnite: string): void {
    this.CodeTypeUnite = CodeTypeUnite;
  }

  public getNumeroCentre(): string {
    return this.NumeroCentre;
  }

  public setNumeroCentre(NumeroCentre: string): void {
    this.NumeroCentre = NumeroCentre;
  }

  public getIndicateurModeReleve(): string {
    return this.IndicateurModeReleve;
  }

  public setIndicateurModeReleve(IndicateurModeReleve: string): void {
    this.IndicateurModeReleve = IndicateurModeReleve;
  }

  public getAdressePda(): string {
    return this.AdressePda;
  }

  public setAdressePda(AdressePda: string): void {
    this.AdressePda = AdressePda;
  }

  public getEmail(): string {
    return this.Email;
  }

  public setEmail(Email: string): void {
    this.Email = Email;
  }

  public getCcEmail(): string {
    return this.CcEmail;
  }

  public setCcEmail(CcEmail: string): void {
    this.CcEmail = CcEmail;
  }

  public getNumeroTelPda(): string {
    return this.NumeroTelPda;
  }

  public setNumeroTelPda(NumeroTelPda: string): void {
    this.NumeroTelPda = NumeroTelPda;
  }

  public getIndicateurModeAffectationLot(): string {
    return this.IndicateurModeAffectationLot;
  }

  public setIndicateurModeAffectationLot(IndicateurModeAffectationLot: string): void {
    this.IndicateurModeAffectationLot = IndicateurModeAffectationLot;
  }

  public getNumeroService(): string {
    return this.NumeroService;
  }

  public setNumeroService(NumeroService: string): void {
    this.NumeroService = NumeroService;
  }

  public getIndicateurIntervention(): string {
    return this.IndicateurIntervention;
  }

  public setIndicateurIntervention(IndicateurIntervention: string): void {
    this.IndicateurIntervention = IndicateurIntervention;
  }

  public getIndicateurReleve(): string {
    return this.IndicateurReleve;
  }

  public setIndicateurReleve(IndicateurReleve: string): void {
    this.IndicateurReleve = IndicateurReleve;
  }

  public getIndicateurInteventionAdministrative(): string {
    return this.IndicateurInteventionAdministrative;
  }

  public setIndicateurInteventionAdministrative(IndicateurInteventionAdministrative: string): void {
    this.IndicateurInteventionAdministrative = IndicateurInteventionAdministrative;
  }

  public getIndicateurMagasin(): string {
    return this.IndicateurMagasin;
  }

  public setIndicateurMagasin(IndicateurMagasin: string): void {
    this.IndicateurMagasin = IndicateurMagasin;
  }

  public getIndicateurService(): string {
    return this.IndicateurService;
  }

  public setIndicateurService(IndicateurService: string): void {
    this.IndicateurService = IndicateurService;
  }

  public getIndicateurInterventionNonPlanifiee(): string {
    return this.IndicateurInterventionNonPlanifiee;
  }

  public setIndicateurInterventionNonPlanifiee(IndicateurInterventionNonPlanifiee: string): void {
    this.IndicateurInterventionNonPlanifiee = IndicateurInterventionNonPlanifiee;
  }

  public getNombreJourAvantDeplanification(): number {
    return this.NombreJourAvantDeplanification ?? 0;
  }

  public setNombreJourAvantDeplanification(NombreJourAvantDeplanification: number): void {
    this.NombreJourAvantDeplanification = NombreJourAvantDeplanification;
  }

  public getNumeroUniteVeolia(): string {
    return this.NumeroUniteVeolia;
  }

  public setNumeroUniteVeolia(NumeroUniteVeolia: string): void {
    this.NumeroUniteVeolia = NumeroUniteVeolia;
  }

  public getIndicateurValeurEditeur(): string {
    return this.IndicateurValeurEditeur;
  }

  public setIndicateurValeurEditeur(IndicateurValeurEditeur: string): void {
    this.IndicateurValeurEditeur = IndicateurValeurEditeur;
  }

  public isIndicateurBasculeMoveo(): boolean {
    return this.IndicateurBasculeMoveo ?? false;
  }

  public setIndicateurBasculeMoveo(IndicateurBasculeMoveo: boolean): void {
    this.IndicateurBasculeMoveo = IndicateurBasculeMoveo;
  }

  public isIndicateurTravo(): boolean {
    return this.IndicateurTravo ?? false;
  }

  public setIndicateurTravo(IndicateurTravo: boolean): void {
    this.IndicateurTravo = IndicateurTravo;
  }

  public getCentre(): Centre {
    return this.Centre;
  }

  public setCentre(Centre: Centre): void {
    this.Centre = Centre;
  }

  public getSociete(): Societe {
    return this.Societe;
  }

  public setSociete(Societe: Societe): void {
    this.Societe = Societe;
  }

  public toOption(): Option<string, string> {
    return new Option(this.Numero, this.Nom);
  }
}
